import React from "react"
import styled from "styled-components"

const StyledArtist = styled.h4`
  font-family: "Gill Sans";
  font-size: 1.2rem;
  font-weight: 400;
  color: #eee;
`

const Artist = ({ artist }) => <StyledArtist>{artist}</StyledArtist>

export default Artist
