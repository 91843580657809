import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import data from "../data.json"

import Artist from "./styled/Artist"
import Cover from "./styled/Cover"
import Song from "./styled/Song"

import Description from "./styled/Description"
import { FaMusic } from "react-icons/fa"

const Index = styled.p`
  margin: 0.5rem 0 0 0;
  font-family: "Gill Sans";
  font-size: 4rem;
  font-weight: 600;
  color: #fff;
  float: right;
  opacity: 0.16;
`

const StyledCard = styled.li`
  transition: 0.2s ease-in-out;
  list-style: none;
  margin-bottom: 2.5rem;
  padding: 2rem 1.25rem 1rem;
  border-radius: 8px;
`

const InfoWrapper = styled.div`
  display: flex;
  margin-bottom: 2rem;
  div {
    margin-left: 0.75rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const Card = (props) => {
  const [scrollTop, setScrollTop] = useState(0)
  const listItemRef = useRef()
  const onScroll = () => {
    setScrollTop(Math.floor(listItemRef.current.getBoundingClientRect().top))
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll)
    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  }, [])

  const checkSongIsPlaying = (current, card) => {
    if (current == card) {
      return true
    } else {
      return false
    }
  }

  return (
    <StyledCard
      style={
        scrollTop >= window.innerHeight * (3 / 4)
          ? { backgroundColor: "#232b32", opacity: ".2" }
          : {
              backgroundColor: "#2d3f4e",
              boxShadow: "0px 14px 25px rgba(0, 0, 0, 0.25)",
              transform: "translateY(-6px)",
            }
      }
      ref={listItemRef}
    >
      <InfoWrapper>
        <Cover
          src={props.value.album.images[1].url}
          alt={props.value.album.name}
          card
        />
        <div>
          <Artist artist={props.value.artists[0].name} />
          {checkSongIsPlaying(
            props.currentSong.item && props.currentSong.item.id,
            props.value.id
          ) ? (
            <FaMusic />
          ) : (
            ""
          )}

          <Song song={props.value.name} />
        </div>
      </InfoWrapper>
      <Description description={data[props.index].text} />
      <Index>{props.index + 1}.</Index>
    </StyledCard>
  )
}

export default Card
