import React from "react"
import styled from "styled-components"
import { getTime } from "../../helpers/getTime"

const StyledTime = styled.time`
  font-family: "Gill Sans";
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.2;
  color: #eee;
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
`

const Time = ({ time }) => (
  <StyledTime datetime={getTime(time)}>{getTime(time)} </StyledTime>
)

export default Time
