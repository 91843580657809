import React from "react"
import styled from "styled-components"

const StyledSong = styled.h3`
  font-family: "Gill Sans";
  font-size: 1.5rem;
  font-weight: 600;
  color: #eee;
`

const Song = ({ song }) => <StyledSong>{song}</StyledSong>

export default Song
