import React from "react"
import styled from "styled-components"
import Card from "./Card"

const StyledList = styled.ul`
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  background-color: #181d21;
`

const List = ({ playlistData, currentSong }) => {
  return (
    <StyledList>
      {playlistData.map((value, index) => {
        return (
          <Card
            value={value.track}
            index={index}
            key={value.track.id.toString()}
            currentSong={currentSong}
          />
        )
      })}
    </StyledList>
  )
}

export default List
