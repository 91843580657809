import React, { useState } from "react"
import styled from "styled-components"
import { FaMusic } from "react-icons/fa"

import Artist from "./styled/Artist"
import Cover from "./styled/Cover"
import Song from "./styled/Song"
import ProgessBar from "./styled/ProgressBar"
import Time from "./styled/Time"
import Description from "./styled/Description"

const StyledCurrentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
`

const StyledCompactProgess = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledWrapper = styled.div`
  width: 100%;
  justify-self: center;
  text-align: center;
  margin: 2rem 0;
  > * {
    margin-bottom: 0.75rem;
  }
`

const Current = (props) => {
  let songProgress = props.currentSong.progress_ms
  let songDuration = props.currentSong.item.duration_ms

  let coverSrc = props.currentSong.item.album.images[0].url
  let coverName = props.currentSong.item.album.name
  let artist = props.currentSong.item.artists[0].name
  let song = props.currentSong.item.name
  let id = props.currentSong.item.id

  const getCurrentDescription = () => {}

  return (
    <StyledCurrentWrapper>
      <Cover src={coverSrc} alt={coverName} current />
      <StyledWrapper>
        <Artist artist={artist} />
        <Song song={song} />
        <StyledCompactProgess>
          <Time time={songProgress} />
          <ProgessBar songDuration={songDuration} songProgress={songProgress} />
          <Time time={songDuration} />
        </StyledCompactProgess>
      </StyledWrapper>
      <Description description={id} />
    </StyledCurrentWrapper>
  )
}

export default Current
