// get duration or dynmaic progress of song as "0:00"
// needs time in ms as parameter

export const getTime = (time) => {
  let minutes = Math.floor(time / 1000 / 60)
  let seconds = Math.floor((time / 1000) % 60)
  // places 0 infront of second if second is 0 - 9
  seconds = seconds.toString().length == 1 ? `0${seconds}` : seconds
  let finalTime = `${minutes}:${seconds}`
  return finalTime
}
