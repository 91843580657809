import React from "react"
import playlistCover from "../img/cover.jpg"
import styled from "styled-components"

const StyledButton = styled.button`
  background-color: #4bf22f;
  border: none;
  border-radius: 30px;
  font-family: "Gill Sans";
  font-size: 1.5rem;
  height: 4rem;
`

const StyledPlaylistCover = styled.img`
  border-radius: 4px;
  max-width: 80%;
  margin: 0 auto 2rem;
`
const StyledHeadline = styled.h3`
  text-align: center;
  color: white;
  font-family: "Gill Sans";
  font-size: 2rem;
  font-weight: 800;
  margin-bottom: 4rem;
`

const SytledLogin = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 2 / 12;
`

const Login = () => {
  return (
    <SytledLogin>
      {/* <img src={playlistCover} alt="Playlist Cover" /> */}
      <StyledPlaylistCover src={playlistCover} alt="Playlist Cover" />
      <StyledHeadline>Memories</StyledHeadline>
      <StyledButton
        onClick={() => {
          window.location = window.location.href.includes("localhost")
            ? "http://localhost:8888/login"
            : "https://nikol-playlist-backend.herokuapp.com/login"
        }}
      >
        Login with Spotify
      </StyledButton>
    </SytledLogin>
  )
}

export default Login
