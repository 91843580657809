import React from "react"
import styled, { css } from "styled-components"

const StyledCover = styled.img`
  width: 100%;
  height: auto;
  border-radius: 6px;
  filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.25));
  /* current */
  ${(props) =>
    props.current &&
    css`
      width: calc(100% / 12 * 8);
      margin: 0 auto;
    `}
  /* card  */
  ${(props) =>
    props.card &&
    css`
      width: 5.43rem;
      height: 5.43rem;
      object-fit: cover;
    `}
`

const Cover = ({ current, alt, src, card }) => (
  <StyledCover
    src={src}
    alt={`Cover of the album: ${alt}`}
    current={current}
    card={card}
  />
)

export default Cover
