import React from "react"
import styled from "styled-components"

const StyledProgessBar = styled.progress`
  height: 0.5rem;
  margin: 0 1rem;
  -webkit-appearance: none;
  border: none;
  &::-webkit-progress-bar {
    background-color: white;
    height: 0.5rem;
    border-radius: 6px;
  }
  &::-webkit-progress-value {
    background-color: #4fe036;
    height: 0.5rem;
    border-radius: 6px;
  }
`

const ProgressBar = ({ songProgress, songDuration }) => (
  <StyledProgessBar value={songProgress / 1000} max={songDuration / 1000} />
)

export default ProgressBar
