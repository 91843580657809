import React from "react"
import styled from "styled-components"

const StyledDescription = styled.p`
  color: #eee;
  line-height: 1.2;
  font-size: 1.25rem;
  font-family: "Gill Sans";
  font-weight: 300;
`

const Description = ({ description }) => (
  <StyledDescription>{description}</StyledDescription>
)

export default Description
