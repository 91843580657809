import React, { useEffect, useState } from "react"
import styled from "styled-components"

import Login from "./components/Login"
import Current from "./components/Current"
import Card from "./components/Card"
import List from "./components/List"

const StyledAppWrapper = styled.div`
  background-color: #181d21;
  padding: 3rem 0;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
`

const StyledDataWrapper = styled.div`
  grid-column: 1 / 13;
`

function App() {
  const [playlistData, setPlaylistData] = useState([])
  const [meData, setMeData] = useState()
  const [currentSong, setCurrentSong] = useState([])

  useEffect(() => {
    // Access Token
    let url = window.location
    let access_token = new URLSearchParams(url.search).get("access_token")
    if (!access_token) return

    // fetch playlist data
    fetch(`https://api.spotify.com/v1/playlists/0weTHdI9D81FX15zV0KN8x`, {
      headers: {
        Authorization: "Bearer " + access_token,
      },
    })
      .then((response) => response.json())
      .then((data) => setPlaylistData(data.tracks.items))

    // fetch user data
    fetch(`https://api.spotify.com/v1/me`, {
      headers: {
        Authorization: "Bearer " + access_token,
      },
    })
      .then((response) => response.json())
      .then((data) => setMeData(data))

    // get current playling song
    // [BUG] fetch should only run if fetch user data == true

    const fetchCurrentSong = () => {
      fetch(`https://api.spotify.com/v1/me/player/currently-playing`, {
        headers: {
          Authorization: "Bearer " + access_token,
        },
      })
        .then((response) => response.json())
        .then((data) => setCurrentSong(data))
    }
    setInterval(fetchCurrentSong, 500)
  }, [])

  const checkUser = (user) => {
    if (
      user === "spotify:user:091sl1rp9cy5dsdr0x2gkk3nx" ||
      user === "spotify:user:calvinhinzer"
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <StyledAppWrapper className="App">
      {meData ? (
        <StyledDataWrapper>
          {currentSong.item ? (
            <Current currentSong={currentSong} />
          ) : (
            <p style={{ color: "white" }}>Loading...</p>
          )}
          {checkUser(meData.uri) ? (
            <List playlistData={playlistData} currentSong={currentSong} />
          ) : (
            <p style={{ color: "red" }}>Loading...</p>
          )}
        </StyledDataWrapper>
      ) : (
        <Login />
      )}
    </StyledAppWrapper>
  )
}

export default App
